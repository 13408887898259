import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources";

export const TableRowLast = (props: { sums: Sums }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (
        <div className="css_tr" style={{ fontWeight: "bold" }}>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>Итого</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Traffic().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Mentor().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.HrManagerKA().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.HeadOfStudy().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.FOT_RG().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Welcome().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.ComedToWelcome().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.ComedToWelcome().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.ComedToWelcome().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Education().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Education().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Education().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Exam().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Exam().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Exam().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Trial().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Trial().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Trial().Price())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Stat().Count())}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Stat().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap" style={{ fontWeight: "bold" }}>{Math.round(props.sums.Stat().Price())}</div>
        </div>
    )
}

import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

export const PaymentAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [sourcesList, setSourcesList] = useState<{ title: string, value: string }[]>([])
    const [typeOfSourcesList, setTypeOfSourcesList] = useState<{ title: string, value: string }[]>([])
    const [mentorsList, setMentorsList] = useState<{ title: string, value: string }[]>([])
    const [recruitersList, setRecruitersList] = useState<{ title: string, value: string }[]>([])
    const [vacancyList, setVacancyList] = useState<{ title: string, value: string }[]>([])

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        startDate: Date,
        endDate: Date,
        paymentType: string,
        amount: number,
        entityId: number,
        mentorId: UniqueId,
        sourceId: number,
        typeOfSourceId: number,
        vacancyId: UniqueId,
    }>({
        startDate: new Date(),
        endDate: new Date(),
        paymentType: "",
        amount: 0,
        entityId: 0,
        mentorId: "",
        sourceId: 0,
        typeOfSourceId: 0,
        vacancyId: "00000000-0000-0000-0000-000000000000",
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
            if (id) {
                useCases?.paymentUseCase.UpdatePayment(id!, form.startDate!, form.endDate!, form.amount!, form.entityId!, form.paymentType!, form.sourceId!, form.typeOfSourceId!, form.vacancyId!, () => { navigate(-1) })
            } else {
                useCases?.paymentUseCase.CreatePayment(form.startDate!, form.endDate!, form.amount!, form.entityId!, form.paymentType!, form.sourceId!, form.typeOfSourceId!, form.vacancyId!, () => { navigate(-1) })
            }
        
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readPaymentById = async (id: UniqueId) => {
        let response = await useCases?.paymentUseCase.ReadPaymentById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                startDate: response?.StartDate()!,
                endDate: response?.EndDate()!,
                paymentType: response?.PaymentType()!,
                amount: response?.Amount()!,
                entityId: response?.EntityId()!,
                mentorId: response?.MentorId()!,
                sourceId: response?.SourceId()!,
                typeOfSourceId: response?.TypeOfSourceId()!,
                vacancyId: response?.VacancyId()!,
            })
        }
    }

    const sources = useTypedSelector(({ source }) => {
        return source
    })

    const typeOfSources = useTypedSelector(({ typeOfSource }) => {
        return typeOfSource
    })

    const mentors = useTypedSelector(({ mentor }) => {
        return mentor
    })

    const recruiters = useTypedSelector(({ recruiter }) => {
        return recruiter
    })

    const vacancies = useTypedSelector(({ vacancy }) => {
        return vacancy
    })

    useEffect(() => {
        if (sources?.sources) {
            let list: { title: string, value: string }[] = []
            sources?.sources.forEach(source => {
                list.push({ title: source.Name(), value: source.Id().toString() })
            });
            setSourcesList([...list])
        }
    }, [sources])

    useEffect(() => {
        if (typeOfSources?.typeOfSources) {
            let list: { title: string, value: string }[] = []
            typeOfSources?.typeOfSources.forEach(source => {
                list.push({ title: source.Name(), value: source.Id().toString() })
            });
            setTypeOfSourcesList([...list])
        }
    }, [typeOfSources])

    useEffect(() => {
        if (mentors?.mentors) {
            let list: { title: string, value: string }[] = []
            mentors?.mentors.forEach(source => {
                list.push({ title: source.Name(), value: source.Id().toString() })
            });
            setMentorsList([...list])
        }
    }, [mentors])

    useEffect(() => {
        if (recruiters?.recruiters) {
            let list: { title: string, value: string }[] = []
            recruiters?.recruiters.forEach(source => {
                list.push({ title: source.Name(), value: source.Id().toString() })
            });
            setRecruitersList([...list])
        }
    }, [recruiters])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy;
    }

    useEffect(() => {
        if (vacancies?.vacancies) {
            let list: { title: string, value: string }[] = [{ title: "Выберите вакансию", value: "" }]
            vacancies?.vacancies.forEach(vacancy => {
                list.push({ title: vacancy.Name() + " (г. " + vacancy.City() + " - " + formatDate(vacancy.Date()) + ")", value: vacancy.Id().toString() })
            });
            setVacancyList([...list])
        }
    }, [vacancies])

    const fieldsList = () => {
        let fields: any = [
            {
                name: "startDate",
                title: "Дата с",
                placeholder: "Укажите дату",
                value: form.startDate,
                type: FiledType.Date,
            },
            {
                name: "endDate",
                title: "Дата по",
                placeholder: "Укажите дату",
                value: form.endDate,
                type: FiledType.Date,
            },

            {
                name: "paymentType",
                title: "Тип платежа",
                placeholder: "Выберите тип платежа",
                value: form.paymentType,
                type: FiledType.Select,
                options: [
                    {
                        title: "Рекрутеру",
                        value: "recruiter"
                    },
                    {
                        title: "Наставнику",
                        value: "mentor"
                    },
                    {
                        title: "Траффик",
                        value: "traffic"
                    },
                    {
                        title: "Менеджер KA",
                        value: "hr_manager_ka"
                    },
                    {
                        title: "HRD масс.найм",
                        value: "hr_director_mass_hiring"
                    },
                    {
                        title: "Руководитель обучения",
                        value: "head_of_study"
                    },
                    {
                        title: "ФОТ РГ (ИС2)",
                        value: "fot_rg"
                    },
                    
                ],
            },
        ]

        switch (form.paymentType) {
            case "mentor":
                fields.push(
                    {
                        name: "mentorId",
                        title: "Наставник",
                        placeholder: "Выберите наставника",
                        value: form.mentorId,
                        type: FiledType.Select,
                        options: mentorsList,
                    }
                );
                break;
            case "recruiter":
                fields.push( {
                        name: "entityId",
                        title: "Рекрутер",
                        placeholder: "Выберите рекрутера",
                        value: form.entityId,
                        type: FiledType.SelectInt,
                        options: recruitersList,
                    }
                );
                break;
            case "traffic":
                fields.push(...[
                    {
                        name: "typeOfSourceId",
                        title: "Тип источника",
                        placeholder: "Выберите тип источника",
                        value: form.typeOfSourceId,
                        type: FiledType.SelectInt,
                        options: typeOfSourcesList,
                    },
                    {
                        name: "sourceId",
                        title: "Источник",
                        placeholder: "Выберите источник",
                        value: form.sourceId,
                        type: FiledType.SelectInt,
                        options: sourcesList,
                    },{
                    name: "vacancyId",
                    title: "Вакансия",
                    placeholder: "Выберите вакансию",
                    value: form.vacancyId,
                    type: FiledType.Select,
                    options: vacancyList,
                }]);
                break;
        }
        fields.push(
            {
                name: "amount",
                title: "Сумма",
                placeholder: "Введите сумму",
                value: form.amount,
                type: FiledType.Number,
                options: [],
            }
        )
        return fields
    }

    useEffect(()=>{
        useCases?.vacancyUseCase.ReadVacancysWithoutDoubles(form.startDate.getMonth()+1)
    },[form.startDate, form.endDate])

    useEffect(() => {
        useCases?.mentorUseCase.ReadMentors()
        useCases?.recruiterUseCase.ReadRecruiters()
        useCases?.sourceUseCase.ReadSources()
        useCases?.typeOfSourceUseCase.ReadTypeOfSources()
    }, [])

    useEffect(() => {
        if (id) {
            readPaymentById(id)
        }
    }, [id])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Платеж /</span> Добавить платеж</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление платежа</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={fieldsList()}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

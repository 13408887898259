import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { ReportSelectionSourceReport } from "../../../../repository/api/generated"
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources"
import { TableRowLast } from "./tableRowLast"

export const KaReport = () => {
    const [error, setError] = useState<string>()
    const [reports, setReports] = useState<ReportSourcesItem[]>([])
    const [sums, setSums] = useState<Sums>()
    const [errorComment, setErrorComment] = useState("")
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timer | null>()
    let { id } = useParams();

    const [monthsList, setMonthsList] = useState<{ name: string, date: string }[]>([])

    const [filter, setFilter] = useState<{
        from: Date | null,
        to: Date | null,
    }>({
        from: null,
        to: null,
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let date = new Date(e.target.value)
        setFilter({
            ...filter,
            [e.target.name]: date
        })
    }

    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const getDates = () => {
        let monthsList: { name: string, date: string }[] = []
        let date = new Date()
        date.setDate(date.getDate() - 180);
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }
        for (let i = 0; i < 7; i++) {
            if (date) {
                monthsList.push({
                    name: months[date.getMonth()] + " " + date.getFullYear(),
                    date: formatDate(date)
                })
            }
            date.setDate(date.getDate() + 30);
            if (date.getDate() < 15) {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() - 1)
                }
            } else {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() + 1)
                }
            }
        }
        setMonthsList([...monthsList])
        console.log(monthsList[monthsList.length - 1].date)
        if (!filter.from) {
            monthChange(monthsList[monthsList.length - 1].date)
        }
    }

    const monthChange = (value: string) => {
        let lastDay = new Date(value)

        lastDay.setMonth(lastDay.getMonth() + 1)
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)
        lastDay.setSeconds(59)
        setFilter({
            ...filter,
            from: new Date(value),
            to: lastDay,
        })
    }




    let useCases = useContext(UseCasesContext)

    const payments = useTypedSelector(({ payment }) => {
        return payment
    })

    const readReport = async () => {
        let result = await useCases?.reportUseCase.ReadKaAllReport(filter.from!, filter.to!)
        if (result instanceof Error) {
            setError(result.message)
        } else {
            if (result) {
                setSums(result!.sums)
                setErrorComment(result.comment)
                setReports(result!.items)
            }
        }

    }

    useEffect(() => {
        if (filter.from && filter.to) {
            readReport()
        }
        getDates()
    }, [filter])

    useEffect(() => {
        useCases?.sourceUseCase.ReadSources()
    }, [])


    /*const scrollDirection = (direction: string) => {
        switch (direction) {
            case "left":
                let timerIdLeft = setInterval(() => {
                    if (document.querySelector('.table_scroll')! && document.querySelector('.table_scroll')!.scrollLeft > 0) {
                        document.querySelector('.table_scroll')!.scroll(document.querySelector('.table_scroll')!.scrollLeft - 25, document.querySelector('.table_scroll')!.scrollTop)
                    }
                }, 20);
                setScrollInterval(timerIdLeft)
                break
            case "right":
                let timerIdRight = setInterval(() => {
                    if (document.querySelector('.table_scroll')!) {
                        document.querySelector('.table_scroll')!.scroll(document.querySelector('.table_scroll')!.scrollLeft + 25, document.querySelector('.table_scroll')!.scrollTop)
                    }
                }, 20);
                setScrollInterval(timerIdRight)
                break
        }
    }

    const clearScroll = () => {
        if (scrollInterval) {
            clearInterval(scrollInterval!)
        }
    }*/

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по источникам /</span> КА Общий</h4>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Месяц:</label>
                                        {filter.from ? <select value={formatDate(filter.from!)} onChange={(e) => monthChange(e.target.value)} name="from" className="form-select mb-3">
                                            {monthsList.map((monthItem) => {
                                                return <option value={monthItem.date}>{monthItem.name}</option>
                                            })}
                                        </select> : <></>}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="table_scroll_main">

                            <div className="table_scroll table">
                                {/*<div className="table_scroll_str table_scroll_str_l" onMouseEnter={() => { scrollDirection("left") }} onMouseLeave={() => { clearScroll() }}>
                                        <img src="/img/str_l.svg" />
                                    </div>
                                    <div className="table_scroll_str table_scroll_str_r" onMouseEnter={() => { scrollDirection("right") }} onMouseLeave={() => { clearScroll() }}>
                                        <img src="/img/str_r.svg" />
                                    </div>*/}
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <div className="css_th">Источник</div>
                                            <div className="css_th">Расход <br />трафик</div>
                                            <div className="css_th">ЗП <br /> Настав</div>
                                            <div className="css_th">ЗП <br />менеджера по КА</div>
                                            <div className="css_th">ЗП <br/> Рук об</div>
                                            <div className="css_th">ФОТ РГ (ИС2)</div>

                                            <div className="css_th">Записан <br /> на W</div>
                                            <div className="css_th">Пришел <br /> на W</div>
                                            <div className="css_th">%</div>
                                            <div className="css_th">Ц</div>
                                            <div className="css_th">Назнач <br /> обуч</div>
                                            <div className="css_th">%</div>
                                            <div className="css_th">Ц</div>
                                            <div className="css_th">Экзамен</div>
                                            <div className="css_th">%</div>
                                            <div className="css_th">Ц</div>
                                            <div className="css_th">Прошел С</div>
                                            <div className="css_th">%</div>
                                            <div className="css_th">Ц</div>
                                            <div className="css_th">Прошел ИС</div>
                                            <div className="css_th">%</div>
                                            <div className="css_th">Ц</div>
                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {reports.map((report, index) => {
                                            return <TableRow report={report} last={reports?.length - 1 == index ? true : false} />
                                        })}
                                        {sums ? <TableRowLast sums={sums} /> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )

}
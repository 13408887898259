/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AmocrmClientUserResponse
 */
export interface AmocrmClientUserResponse {
    /**
     * 
     * @type {number}
     * @memberof AmocrmClientUserResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmocrmClientUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {AmocrmClientUserRights}
     * @memberof AmocrmClientUserResponse
     */
    'rights'?: AmocrmClientUserRights;
}
/**
 * 
 * @export
 * @interface AmocrmClientUserRights
 */
export interface AmocrmClientUserRights {
    /**
     * 
     * @type {number}
     * @memberof AmocrmClientUserRights
     */
    'role_id'?: number;
}
/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface DeliveryErrorResponse
 */
export interface DeliveryErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DenyStageCreateDenyStageRequest
 */
export interface DenyStageCreateDenyStageRequest {
    /**
     * 
     * @type {string}
     * @memberof DenyStageCreateDenyStageRequest
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof DenyStageCreateDenyStageRequest
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface HrReportInternalDeliveryAuthSignInResponse
 */
export interface HrReportInternalDeliveryAuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof HrReportInternalDeliveryAuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof HrReportInternalDeliveryAuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface MentorManualAmoResumeProcessRequest
 */
export interface MentorManualAmoResumeProcessRequest {
    /**
     * 
     * @type {number}
     * @memberof MentorManualAmoResumeProcessRequest
     */
    'responsible_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorManualAmoResumeProcessRequest
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface MentorMentorResponse
 */
export interface MentorMentorResponse {
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'mentorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'mentorInternshipId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'mentorProbationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MentorMentorResponse
     */
    'mentorStudyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorMentorResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PaymentCreatePaymentRequest
 */
export interface PaymentCreatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'mentorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'paymentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'sourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentCreatePaymentRequest
     */
    'typeOfSourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentCreatePaymentRequest
     */
    'vacancyId'?: string;
}
/**
 * 
 * @export
 * @interface PaymentPaymentResponse
 */
export interface PaymentPaymentResponse {
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'mentorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'paymentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'sourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPaymentResponse
     */
    'typeOfSourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPaymentResponse
     */
    'vacancyId'?: string;
}
/**
 * 
 * @export
 * @interface PaymentUpdatePaymentRequest
 */
export interface PaymentUpdatePaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentUpdatePaymentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentUpdatePaymentRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentUpdatePaymentRequest
     */
    'entityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentUpdatePaymentRequest
     */
    'mentorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentUpdatePaymentRequest
     */
    'paymentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentUpdatePaymentRequest
     */
    'sourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentUpdatePaymentRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentUpdatePaymentRequest
     */
    'typeOfSourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentUpdatePaymentRequest
     */
    'vacancyId'?: string;
}
/**
 * 
 * @export
 * @interface RecruiterRecruiterResponse
 */
export interface RecruiterRecruiterResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RecruiterRecruiterResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecruiterRecruiterResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecruiterRecruiterResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecruiterRecruiterResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecruiterRecruiterResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReportAutoRecruitersReport
 */
export interface ReportAutoRecruitersReport {
    /**
     * 
     * @type {Array<ReportAutoRecruitersReportItem>}
     * @memberof ReportAutoRecruitersReport
     */
    'items'?: Array<ReportAutoRecruitersReportItem>;
    /**
     * 
     * @type {ReportAutoRecruitersSum}
     * @memberof ReportAutoRecruitersReport
     */
    'sum'?: ReportAutoRecruitersSum;
}
/**
 * 
 * @export
 * @interface ReportAutoRecruitersReportItem
 */
export interface ReportAutoRecruitersReportItem {
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'end_to_end_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'exam_passed_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'exam_passed_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'internship_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'internship_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'leads_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'probation_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'probation_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportAutoRecruitersReportItem
     */
    'recruiters_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'state_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'state_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'success_interviews_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'success_interviews_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'welcome_appearence'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'welcome_appearence_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'welcome_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersReportItem
     */
    'welcome_count'?: number;
}
/**
 * 
 * @export
 * @interface ReportAutoRecruitersSum
 */
export interface ReportAutoRecruitersSum {
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'end_to_end_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'exam_passed_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'exam_passed_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'internship_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'internship_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'leads_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'probation_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'probation_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'state_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'state_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'success_interviews_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'success_interviews_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'welcome_appearence'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'welcome_appearence_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'welcome_conversion'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportAutoRecruitersSum
     */
    'welcome_count'?: number;
}
/**
 * 
 * @export
 * @interface ReportCandidatesReport
 */
export interface ReportCandidatesReport {
    /**
     * EndToEndProbationConversion - ProbationStartedCount / WelcomeCount
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'end_to_end_probation_conversion'?: number;
    /**
     * InternshipDenyCount - сделки, у которых в поле \"этап отказа\" = \"СТ Д1\", \"СТ Д2\", \"СТ Д3\", \"СТ Д4\", \"СТ Д5\", \"Доп. Стажировка\"
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'internship_deny_count'?: number;
    /**
     * InternshipStartedConversion - InternshipStartedCount / PassedExamCount
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'internship_started_conversion'?: number;
    /**
     * InternshipStartedCount - количество сделок в поле \"Дата Стажировка\" есть значение
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'internship_started_count'?: number;
    /**
     * PassedExamConversion - PassedExamCount / StudyStartedCount
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'passed_exam_conversion'?: number;
    /**
     * PassedExamCount - кол-во сдавших экзамен
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'passed_exam_count'?: number;
    /**
     * ProbationDenyCount - сделки, у которых в поле \"этап отказа\" = \"ИС\", вытягивать количество с разбивкой по причинам из поля \"Причина отказа\"
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'probation_deny_count'?: number;
    /**
     * ProbationStartedConversion - ProbationStartedCount / InternshipStartedCount
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'probation_started_conversion'?: number;
    /**
     * ProbationStartedCount - кол-во вышедших на ИС
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'probation_started_count'?: number;
    /**
     * StateCount - вышли в штат
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'state_count'?: number;
    /**
     * StateCountConversion - StateCount / ProbationStartedCount
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'state_count_conversion'?: number;
    /**
     * StudyConversion - конверсия в обучение (StudyStartedCount / WelcomeAppearenceCount)
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'study_conversion'?: number;
    /**
     * StudyDenyCount - отказы на этапе обучения (этап отказа = \"Д1 14ч\", \"Д1 17ч\", \"Д2 утро\", \"Д2 ролевки\", \"Д3 курс\", \"Д3 экзамен\")
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'study_deny_count'?: number;
    /**
     * StudyStartedCount - количество приступивших к обучению (в поле \"Наставник Обучение\" есть значение)
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'study_started_count'?: number;
    /**
     * WelcomeAppearenceConversion - явка на Welcome (WelcomeAppearenceCount / WelcomeCount)
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'welcome_appearence_conversion'?: number;
    /**
     * WelcomeAppearenceCount - количество вышедших на welcome
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'welcome_appearence_count'?: number;
    /**
     * WelcomeCount - количество приглашенных
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'welcome_count'?: number;
    /**
     * WelcomeDenyCount -  сделки, у которых в поле \"этап отказа\" = \"Welcome\"
     * @type {number}
     * @memberof ReportCandidatesReport
     */
    'welcome_deny_count'?: number;
}
/**
 * 
 * @export
 * @interface ReportMentorReportItem
 */
export interface ReportMentorReportItem {
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItem
     */
    'department_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportMentorReportItem
     */
    'department_name'?: string;
    /**
     * 
     * @type {ReportMentorReportItemEducation}
     * @memberof ReportMentorReportItem
     */
    'education'?: ReportMentorReportItemEducation;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportMentorReportItem
     */
    'exam'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportMentorReportItem
     */
    'internshipFinish'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportMentorReportItem
     */
    'internshipStart'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {string}
     * @memberof ReportMentorReportItem
     */
    'mentor_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMentorReportItem
     */
    'mentor_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItem
     */
    'payment'?: number;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportMentorReportItem
     */
    'probationFinish'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportMentorReportItem
     */
    'probationStart'?: ReportMentorReportItemExam;
}
/**
 * 
 * @export
 * @interface ReportMentorReportItemEducation
 */
export interface ReportMentorReportItemEducation {
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItemEducation
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItemEducation
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface ReportMentorReportItemExam
 */
export interface ReportMentorReportItemExam {
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItemExam
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItemExam
     */
    'percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportMentorReportItemExam
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface ReportMentorsReport
 */
export interface ReportMentorsReport {
    /**
     * 
     * @type {Array<ReportMentorReportItem>}
     * @memberof ReportMentorsReport
     */
    'items'?: Array<ReportMentorReportItem>;
}
/**
 * 
 * @export
 * @interface ReportRecruiterReportItem
 */
export interface ReportRecruiterReportItem {
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportItem
     */
    'negotations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportItem
     */
    'payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportItem
     */
    'recruiter_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportRecruiterReportItem
     */
    'recruiter_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportItem
     */
    'spent_money'?: number;
}
/**
 * 
 * @export
 * @interface ReportRecruiterReportSource
 */
export interface ReportRecruiterReportSource {
    /**
     * 
     * @type {Array<ReportRecruiterReportItem>}
     * @memberof ReportRecruiterReportSource
     */
    'detalization'?: Array<ReportRecruiterReportItem>;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSource
     */
    'negotations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSource
     */
    'payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSource
     */
    'source_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportRecruiterReportSource
     */
    'source_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSource
     */
    'spent_money'?: number;
}
/**
 * 
 * @export
 * @interface ReportRecruiterReportSums
 */
export interface ReportRecruiterReportSums {
    /**
     * 
     * @type {Array<ReportRecruiterReportSumsItem>}
     * @memberof ReportRecruiterReportSums
     */
    'detalization'?: Array<ReportRecruiterReportSumsItem>;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSums
     */
    'negotations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSums
     */
    'payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSums
     */
    'spent_money'?: number;
}
/**
 * 
 * @export
 * @interface ReportRecruiterReportSumsItem
 */
export interface ReportRecruiterReportSumsItem {
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSumsItem
     */
    'negotations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSumsItem
     */
    'payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSumsItem
     */
    'recruiter_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportRecruiterReportSumsItem
     */
    'recruiter_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRecruiterReportSumsItem
     */
    'spent_money'?: number;
}
/**
 * 
 * @export
 * @interface ReportRecruitersReport
 */
export interface ReportRecruitersReport {
    /**
     * 
     * @type {Array<ReportRecruiterReportSource>}
     * @memberof ReportRecruitersReport
     */
    'sources_list'?: Array<ReportRecruiterReportSource>;
    /**
     * 
     * @type {ReportRecruiterReportSums}
     * @memberof ReportRecruitersReport
     */
    'sums'?: ReportRecruiterReportSums;
}
/**
 * 
 * @export
 * @interface ReportReport
 */
export interface ReportReport {
    /**
     * 
     * @type {ReportReportIdent}
     * @memberof ReportReport
     */
    'ident'?: ReportReportIdent;
    /**
     * 
     * @type {string}
     * @memberof ReportReport
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportReportIdent = {
    SelectionSourcesReport: 'sources_classic_report',
    AcademySourcesReport: 'sources_academy_report',
    AllSourcesReport: 'sources_academy_and_classic_report',
    KaReport: 'ka_report',
    AkcReport: 'akc_report',
    PaymentsReport: 'payments_report',
    Recruiters: 'recruiters',
    Mentors: 'mentors',
    RecruitersAuto: 'recruiters_auto',
    CandidatesAuto: 'candidates_auto'
} as const;

export type ReportReportIdent = typeof ReportReportIdent[keyof typeof ReportReportIdent];


/**
 * 
 * @export
 * @interface ReportSelectionSourceReport
 */
export interface ReportSelectionSourceReport {
    /**
     * 
     * @type {string}
     * @memberof ReportSelectionSourceReport
     */
    'comment'?: string;
    /**
     * 
     * @type {{ [key: string]: ReportSourceReport; }}
     * @memberof ReportSelectionSourceReport
     */
    'items'?: { [key: string]: ReportSourceReport; };
    /**
     * 
     * @type {ReportSums}
     * @memberof ReportSelectionSourceReport
     */
    'sums'?: ReportSums;
}
/**
 * 
 * @export
 * @interface ReportSourceReport
 */
export interface ReportSourceReport {
    /**
     * 
     * @type {ReportSourceReportComedToWelcome}
     * @memberof ReportSourceReport
     */
    'comed_to_welcome'?: ReportSourceReportComedToWelcome;
    /**
     * 
     * @type {ReportSourceReportEducation}
     * @memberof ReportSourceReport
     */
    'education'?: ReportSourceReportEducation;
    /**
     * 
     * @type {ReportSourceReportExam}
     * @memberof ReportSourceReport
     */
    'exam'?: ReportSourceReportExam;
    /**
     * 
     * @type {ReportSourceReportInterview}
     * @memberof ReportSourceReport
     */
    'interview'?: ReportSourceReportInterview;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReport
     */
    'negotation_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReport
     */
    'negotations_count'?: number;
    /**
     * 
     * @type {ReportSourceReportPayments}
     * @memberof ReportSourceReport
     */
    'payments'?: ReportSourceReportPayments;
    /**
     * 
     * @type {ReportSourceReportProfit}
     * @memberof ReportSourceReport
     */
    'profit'?: ReportSourceReportProfit;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReport
     */
    'source_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSourceReport
     */
    'source_name'?: string;
    /**
     * 
     * @type {ReportSourceReportStat}
     * @memberof ReportSourceReport
     */
    'stat'?: ReportSourceReportStat;
    /**
     * 
     * @type {ReportSourceReportTrial}
     * @memberof ReportSourceReport
     */
    'trial'?: ReportSourceReportTrial;
    /**
     * 
     * @type {ReportSourceReportWelcome}
     * @memberof ReportSourceReport
     */
    'welcome'?: ReportSourceReportWelcome;
}
/**
 * 
 * @export
 * @interface ReportSourceReportComedToWelcome
 */
export interface ReportSourceReportComedToWelcome {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportComedToWelcome
     */
    'comed_to_welcome_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportComedToWelcome
     */
    'comed_to_welcome_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportComedToWelcome
     */
    'comed_to_welcome_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportEducation
 */
export interface ReportSourceReportEducation {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportEducation
     */
    'education_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportEducation
     */
    'education_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportEducation
     */
    'education_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportExam
 */
export interface ReportSourceReportExam {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportExam
     */
    'exam_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportExam
     */
    'exam_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportExam
     */
    'exam_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportInterview
 */
export interface ReportSourceReportInterview {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportInterview
     */
    'interview_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportInterview
     */
    'interview_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportInterview
     */
    'interview_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportPayments
 */
export interface ReportSourceReportPayments {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'hr_manager_ka_payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'fot_rg_payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'head_of_study_payments'?: number;
     /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'hr_director_mass_hiring_payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'mentor_payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'recruiter_payments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportPayments
     */
    'traffic_payments'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportProfit
 */
export interface ReportSourceReportProfit {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportProfit
     */
    'profit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportProfit
     */
    'romi'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportStat
 */
export interface ReportSourceReportStat {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportStat
     */
    'stat_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportStat
     */
    'stat_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportStat
     */
    'stat_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportTrial
 */
export interface ReportSourceReportTrial {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportTrial
     */
    'trial_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportTrial
     */
    'trial_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportTrial
     */
    'trial_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSourceReportWelcome
 */
export interface ReportSourceReportWelcome {
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportWelcome
     */
    'welcome_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportWelcome
     */
    'welcome_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportSourceReportWelcome
     */
    'welcome_price'?: number;
}
/**
 * 
 * @export
 * @interface ReportSums
 */
export interface ReportSums {
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'comedToWelcome'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'education'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'exam'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'hr_manager_ka'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'hr_director_mass_hiring'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'head_of_study'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'fot_rg'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'interview'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'mentor'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'negotations'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportSourceReportProfit}
     * @memberof ReportSums
     */
    'profit'?: ReportSourceReportProfit;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'recruiter'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'stat'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportSumsHrManager}
     * @memberof ReportSums
     */
    'traffic'?: ReportSumsHrManager;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'trial'?: ReportMentorReportItemExam;
    /**
     * 
     * @type {ReportMentorReportItemExam}
     * @memberof ReportSums
     */
    'welcome'?: ReportMentorReportItemExam;
}
/**
 * 
 * @export
 * @interface ReportSumsHrManager
 */
export interface ReportSumsHrManager {
    /**
     * 
     * @type {number}
     * @memberof ReportSumsHrManager
     */
    'payment'?: number;
}
/**
 * 
 * @export
 * @interface SourceSourceResponse
 */
export interface SourceSourceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SourceSourceResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SourceSourceResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof SourceSourceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SourceSourceResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceSourceResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TypeOfSourceTypeOfSourceResponse
 */
export interface TypeOfSourceTypeOfSourceResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TypeOfSourceTypeOfSourceResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TypeOfSourceTypeOfSourceResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeOfSourceTypeOfSourceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeOfSourceTypeOfSourceResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeOfSourceTypeOfSourceResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateUserRequest
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateUserRequest
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUserResponse
     */
    'access_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface VacancyVacancyResponse
 */
export interface VacancyVacancyResponse {
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VacancyVacancyResponse
     */
    'type'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HrReportInternalDeliveryAuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<HrReportInternalDeliveryAuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKaOperatorsHookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ka/operators/hook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKaOperatorsHookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKaOperatorsHookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKaOperatorsHookPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiKaOperatorsHookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiKaOperatorsHookPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiKaOperatorsHookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DenyStageApi - axios parameter creator
 * @export
 */
export const DenyStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список распределенных denyStage.
         * @summary получить список распределенных denyStage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deny-stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить denyStage.
         * @summary удалить denyStage.
         * @param {string} name Идентификатор DenyStage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageNameDelete: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiDenyStageNameDelete', 'name', name)
            const localVarPath = `/api/deny-stage/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список неиспользованных значений.
         * @summary получить список неиспользованных значений.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageNotUsedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deny-stage/not-used`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание deny_stage.
         * @summary создание deny_stage.
         * @param {DenyStageCreateDenyStageRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStagePost: async (user: DenyStageCreateDenyStageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiDenyStagePost', 'user', user)
            const localVarPath = `/api/deny-stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DenyStageApi - functional programming interface
 * @export
 */
export const DenyStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DenyStageApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список распределенных denyStage.
         * @summary получить список распределенных denyStage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDenyStageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDenyStageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить denyStage.
         * @summary удалить denyStage.
         * @param {string} name Идентификатор DenyStage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDenyStageNameDelete(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDenyStageNameDelete(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список неиспользованных значений.
         * @summary получить список неиспользованных значений.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDenyStageNotUsedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDenyStageNotUsedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание deny_stage.
         * @summary создание deny_stage.
         * @param {DenyStageCreateDenyStageRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDenyStagePost(user: DenyStageCreateDenyStageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDenyStagePost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DenyStageApi - factory interface
 * @export
 */
export const DenyStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DenyStageApiFp(configuration)
    return {
        /**
         * получить список распределенных denyStage.
         * @summary получить список распределенных denyStage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageGet(options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.apiDenyStageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить denyStage.
         * @summary удалить denyStage.
         * @param {string} name Идентификатор DenyStage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageNameDelete(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDenyStageNameDelete(name, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список неиспользованных значений.
         * @summary получить список неиспользованных значений.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStageNotUsedGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiDenyStageNotUsedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * создание deny_stage.
         * @summary создание deny_stage.
         * @param {DenyStageCreateDenyStageRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDenyStagePost(user: DenyStageCreateDenyStageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiDenyStagePost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DenyStageApi - object-oriented interface
 * @export
 * @class DenyStageApi
 * @extends {BaseAPI}
 */
export class DenyStageApi extends BaseAPI {
    /**
     * получить список распределенных denyStage.
     * @summary получить список распределенных denyStage.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenyStageApi
     */
    public apiDenyStageGet(options?: AxiosRequestConfig) {
        return DenyStageApiFp(this.configuration).apiDenyStageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить denyStage.
     * @summary удалить denyStage.
     * @param {string} name Идентификатор DenyStage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenyStageApi
     */
    public apiDenyStageNameDelete(name: string, options?: AxiosRequestConfig) {
        return DenyStageApiFp(this.configuration).apiDenyStageNameDelete(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список неиспользованных значений.
     * @summary получить список неиспользованных значений.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenyStageApi
     */
    public apiDenyStageNotUsedGet(options?: AxiosRequestConfig) {
        return DenyStageApiFp(this.configuration).apiDenyStageNotUsedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание deny_stage.
     * @summary создание deny_stage.
     * @param {DenyStageCreateDenyStageRequest} user Данные платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DenyStageApi
     */
    public apiDenyStagePost(user: DenyStageCreateDenyStageRequest, options?: AxiosRequestConfig) {
        return DenyStageApiFp(this.configuration).apiDenyStagePost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmAuthGet: async (id: string, clientId: string, code: string, referer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('amocrmAuthGet', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('amocrmAuthGet', 'clientId', clientId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('amocrmAuthGet', 'code', code)
            // verify required parameter 'referer' is not null or undefined
            assertParamExists('amocrmAuthGet', 'referer', referer)
            const localVarPath = `/amocrm/auth`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmCodeGet: async (id: string, clientId: string, code: string, referer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('amocrmCodeGet', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('amocrmCodeGet', 'clientId', clientId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('amocrmCodeGet', 'code', code)
            // verify required parameter 'referer' is not null or undefined
            assertParamExists('amocrmCodeGet', 'referer', referer)
            const localVarPath = `/amocrm/code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получаем роль пользователя по его id.
         * @summary Получаем роль пользователя по его id.
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmRoleIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('amocrmRoleIdPost', 'id', id)
            const localVarPath = `/amocrm/role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amocrmAuthGet(id, clientId, code, referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amocrmCodeGet(id, clientId, code, referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получаем роль пользователя по его id.
         * @summary Получаем роль пользователя по его id.
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amocrmRoleIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amocrmRoleIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: any): AxiosPromise<void> {
            return localVarFp.amocrmAuthGet(id, clientId, code, referer, options).then((request) => request(axios, basePath));
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: any): AxiosPromise<void> {
            return localVarFp.amocrmCodeGet(id, clientId, code, referer, options).then((request) => request(axios, basePath));
        },
        /**
         * Получаем роль пользователя по его id.
         * @summary Получаем роль пользователя по его id.
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmRoleIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.amocrmRoleIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * Редирект на страницу авторизации приложения в амо.
     * @summary Редирект на страницу авторизации приложения в амо.
     * @param {string} id Project ID
     * @param {string} clientId 
     * @param {string} code 
     * @param {string} referer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).amocrmAuthGet(id, clientId, code, referer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
     * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
     * @param {string} id Project ID
     * @param {string} clientId 
     * @param {string} code 
     * @param {string} referer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).amocrmCodeGet(id, clientId, code, referer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получаем роль пользователя по его id.
     * @summary Получаем роль пользователя по его id.
     * @param {string} id User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public amocrmRoleIdPost(id: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).amocrmRoleIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MentorsApi - axios parameter creator
 * @export
 */
export const MentorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех менторов.
         * @summary получить список всех менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mentor/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обработать резюме открытое в ручную.
         * @summary обработать резюме открытое в ручную.
         * @param {MentorManualAmoResumeProcessRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorResumePost: async (user: MentorManualAmoResumeProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiMentorResumePost', 'user', user)
            const localVarPath = `/api/mentor/resume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех пользователей.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mentor/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обработать лиды по новой.
         * @summary обработать лиды по новой.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsClearGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leads/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MentorsApi - functional programming interface
 * @export
 */
export const MentorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MentorsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех менторов.
         * @summary получить список всех менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MentorMentorResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обработать резюме открытое в ручную.
         * @summary обработать резюме открытое в ручную.
         * @param {MentorManualAmoResumeProcessRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorResumePost(user: MentorManualAmoResumeProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorResumePost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех пользователей.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMentorUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmocrmClientUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMentorUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обработать лиды по новой.
         * @summary обработать лиды по новой.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsClearGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsClearGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MentorsApi - factory interface
 * @export
 */
export const MentorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MentorsApiFp(configuration)
    return {
        /**
         * получить список всех менторов.
         * @summary получить список всех менторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorGet(options?: any): AxiosPromise<Array<MentorMentorResponse>> {
            return localVarFp.apiMentorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * обработать резюме открытое в ручную.
         * @summary обработать резюме открытое в ручную.
         * @param {MentorManualAmoResumeProcessRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorResumePost(user: MentorManualAmoResumeProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiMentorResumePost(user, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех пользователей.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMentorUsersGet(options?: any): AxiosPromise<Array<AmocrmClientUserResponse>> {
            return localVarFp.apiMentorUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * обработать лиды по новой.
         * @summary обработать лиды по новой.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsClearGet(options?: any): AxiosPromise<void> {
            return localVarFp.leadsClearGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MentorsApi - object-oriented interface
 * @export
 * @class MentorsApi
 * @extends {BaseAPI}
 */
export class MentorsApi extends BaseAPI {
    /**
     * получить список всех менторов.
     * @summary получить список всех менторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorsApi
     */
    public apiMentorGet(options?: AxiosRequestConfig) {
        return MentorsApiFp(this.configuration).apiMentorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обработать резюме открытое в ручную.
     * @summary обработать резюме открытое в ручную.
     * @param {MentorManualAmoResumeProcessRequest} user Данные платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorsApi
     */
    public apiMentorResumePost(user: MentorManualAmoResumeProcessRequest, options?: AxiosRequestConfig) {
        return MentorsApiFp(this.configuration).apiMentorResumePost(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех пользователей.
     * @summary получить список всех пользователей.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorsApi
     */
    public apiMentorUsersGet(options?: AxiosRequestConfig) {
        return MentorsApiFp(this.configuration).apiMentorUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обработать лиды по новой.
     * @summary обработать лиды по новой.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MentorsApi
     */
    public leadsClearGet(options?: AxiosRequestConfig) {
        return MentorsApiFp(this.configuration).leadsClearGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех платежей.
         * @summary получить список всех платежей.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {number} [mentor] Id ментора
         * @param {number} [recruiter] Id рекрутера
         * @param {number} [source] Id источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentGet: async (from?: string, to?: string, mentor?: number, recruiter?: number, source?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (mentor !== undefined) {
                localVarQueryParameter['mentor'] = mentor;
            }

            if (recruiter !== undefined) {
                localVarQueryParameter['recruiter'] = recruiter;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить платеж.
         * @summary удалить платеж.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentIdDelete', 'id', id)
            const localVarPath = `/api/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentIdGet', 'id', id)
            const localVarPath = `/api/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление платежа.
         * @summary обновление платежа.
         * @param {string} id Идентификатор платежа
         * @param {PaymentUpdatePaymentRequest} user Данные для обновления платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdPut: async (id: string, user: PaymentUpdatePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiPaymentIdPut', 'user', user)
            const localVarPath = `/api/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание платежа.
         * @summary создание платежа.
         * @param {PaymentCreatePaymentRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPost: async (user: PaymentCreatePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiPaymentPost', 'user', user)
            const localVarPath = `/api/payment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех платежей.
         * @summary получить список всех платежей.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {number} [mentor] Id ментора
         * @param {number} [recruiter] Id рекрутера
         * @param {number} [source] Id источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentGet(from?: string, to?: string, mentor?: number, recruiter?: number, source?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentPaymentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentGet(from, to, mentor, recruiter, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить платеж.
         * @summary удалить платеж.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление платежа.
         * @summary обновление платежа.
         * @param {string} id Идентификатор платежа
         * @param {PaymentUpdatePaymentRequest} user Данные для обновления платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentIdPut(id: string, user: PaymentUpdatePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание платежа.
         * @summary создание платежа.
         * @param {PaymentCreatePaymentRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPost(user: PaymentCreatePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * получить список всех платежей.
         * @summary получить список всех платежей.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {number} [mentor] Id ментора
         * @param {number} [recruiter] Id рекрутера
         * @param {number} [source] Id источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentGet(from?: string, to?: string, mentor?: number, recruiter?: number, source?: number, options?: any): AxiosPromise<Array<PaymentPaymentResponse>> {
            return localVarFp.apiPaymentGet(from, to, mentor, recruiter, source, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить платеж.
         * @summary удалить платеж.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdDelete(id: string, options?: any): AxiosPromise<PaymentPaymentResponse> {
            return localVarFp.apiPaymentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdGet(id: string, options?: any): AxiosPromise<PaymentPaymentResponse> {
            return localVarFp.apiPaymentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление платежа.
         * @summary обновление платежа.
         * @param {string} id Идентификатор платежа
         * @param {PaymentUpdatePaymentRequest} user Данные для обновления платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentIdPut(id: string, user: PaymentUpdatePaymentRequest, options?: any): AxiosPromise<PaymentPaymentResponse> {
            return localVarFp.apiPaymentIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание платежа.
         * @summary создание платежа.
         * @param {PaymentCreatePaymentRequest} user Данные платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPost(user: PaymentCreatePaymentRequest, options?: any): AxiosPromise<PaymentPaymentResponse> {
            return localVarFp.apiPaymentPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * получить список всех платежей.
     * @summary получить список всех платежей.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {number} [mentor] Id ментора
     * @param {number} [recruiter] Id рекрутера
     * @param {number} [source] Id источника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentGet(from?: string, to?: string, mentor?: number, recruiter?: number, source?: number, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentGet(from, to, mentor, recruiter, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить платеж.
     * @summary удалить платеж.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentIdDelete(id: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentIdGet(id: string, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление платежа.
     * @summary обновление платежа.
     * @param {string} id Идентификатор платежа
     * @param {PaymentUpdatePaymentRequest} user Данные для обновления платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentIdPut(id: string, user: PaymentUpdatePaymentRequest, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание платежа.
     * @summary создание платежа.
     * @param {PaymentCreatePaymentRequest} user Данные платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentPost(user: PaymentCreatePaymentRequest, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecruitersApi - axios parameter creator
 * @export
 */
export const RecruitersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех рекрутеров.
         * @summary получить список всех рекрутеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecruiterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recruiter/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecruitersApi - functional programming interface
 * @export
 */
export const RecruitersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecruitersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех рекрутеров.
         * @summary получить список всех рекрутеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecruiterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecruiterRecruiterResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecruiterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecruitersApi - factory interface
 * @export
 */
export const RecruitersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecruitersApiFp(configuration)
    return {
        /**
         * получить список всех рекрутеров.
         * @summary получить список всех рекрутеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecruiterGet(options?: any): AxiosPromise<Array<RecruiterRecruiterResponse>> {
            return localVarFp.apiRecruiterGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecruitersApi - object-oriented interface
 * @export
 * @class RecruitersApi
 * @extends {BaseAPI}
 */
export class RecruitersApi extends BaseAPI {
    /**
     * получить список всех рекрутеров.
     * @summary получить список всех рекрутеров.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitersApi
     */
    public apiRecruiterGet(options?: AxiosRequestConfig) {
        return RecruitersApiFp(this.configuration).apiRecruiterGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * отчет по источникам наш академия.
         * @summary отчет по источникам наш академия.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAcademyGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/academy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет по источникам АКЦ.
         * @summary отчет по источникам АКЦ.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAkcAllGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/akc-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет по источникам наш академия + классик.
         * @summary отчет по источникам наш академия + классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAllSourcesGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/all-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет по кандидатам.
         * @summary отчет по кандидатам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {string} type Тип источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCandidatesFromToTypeGet: async (from: string, to: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiReportCandidatesFromToTypeGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiReportCandidatesFromToTypeGet', 'to', to)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiReportCandidatesFromToTypeGet', 'type', type)
            const localVarPath = `/api/report/candidates/{from}/{to}/{type}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет по источникам наш классик.
         * @summary отчет по источникам наш классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportClassicGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/classic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет по источникам КА.
         * @summary отчет по источникам КА.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportKaAllGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/ka-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * список отчетов.
         * @summary список отчетов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportMentorsGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/mentors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * автоматический отчет по рекрутерам.
         * @summary автоматический отчет по рекрутерам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportRecruitersAutoFromToGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiReportRecruitersAutoFromToGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiReportRecruitersAutoFromToGet', 'to', to)
            const localVarPath = `/api/report/recruiters-auto/{from}/{to}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportRecruitersGet: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/recruiters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * отчет по источникам наш академия.
         * @summary отчет по источникам наш академия.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportAcademyGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSelectionSourceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportAcademyGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет по источникам АКЦ.
         * @summary отчет по источникам АКЦ.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportAkcAllGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSelectionSourceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportAkcAllGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет по источникам наш академия + классик.
         * @summary отчет по источникам наш академия + классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportAllSourcesGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSelectionSourceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportAllSourcesGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет по кандидатам.
         * @summary отчет по кандидатам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {string} type Тип источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportCandidatesFromToTypeGet(from: string, to: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportCandidatesReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportCandidatesFromToTypeGet(from, to, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет по источникам наш классик.
         * @summary отчет по источникам наш классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportClassicGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSelectionSourceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportClassicGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет по источникам КА.
         * @summary отчет по источникам КА.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportKaAllGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSelectionSourceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportKaAllGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * список отчетов.
         * @summary список отчетов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportMentorsGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportMentorsReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportMentorsGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * автоматический отчет по рекрутерам.
         * @summary автоматический отчет по рекрутерам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportRecruitersAutoFromToGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportAutoRecruitersReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportRecruitersAutoFromToGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportRecruitersGet(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRecruitersReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportRecruitersGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * отчет по источникам наш академия.
         * @summary отчет по источникам наш академия.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAcademyGet(from?: string, to?: string, options?: any): AxiosPromise<ReportSelectionSourceReport> {
            return localVarFp.apiReportAcademyGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет по источникам АКЦ.
         * @summary отчет по источникам АКЦ.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAkcAllGet(from?: string, to?: string, options?: any): AxiosPromise<ReportSelectionSourceReport> {
            return localVarFp.apiReportAkcAllGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет по источникам наш академия + классик.
         * @summary отчет по источникам наш академия + классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportAllSourcesGet(from?: string, to?: string, options?: any): AxiosPromise<ReportSelectionSourceReport> {
            return localVarFp.apiReportAllSourcesGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет по кандидатам.
         * @summary отчет по кандидатам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {string} type Тип источника
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportCandidatesFromToTypeGet(from: string, to: string, type: string, options?: any): AxiosPromise<ReportCandidatesReport> {
            return localVarFp.apiReportCandidatesFromToTypeGet(from, to, type, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет по источникам наш классик.
         * @summary отчет по источникам наш классик.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportClassicGet(from?: string, to?: string, options?: any): AxiosPromise<ReportSelectionSourceReport> {
            return localVarFp.apiReportClassicGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет по источникам КА.
         * @summary отчет по источникам КА.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportKaAllGet(from?: string, to?: string, options?: any): AxiosPromise<ReportSelectionSourceReport> {
            return localVarFp.apiReportKaAllGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * список отчетов.
         * @summary список отчетов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportListGet(options?: any): AxiosPromise<Array<ReportReport>> {
            return localVarFp.apiReportListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportMentorsGet(from?: string, to?: string, options?: any): AxiosPromise<ReportMentorsReport> {
            return localVarFp.apiReportMentorsGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * автоматический отчет по рекрутерам.
         * @summary автоматический отчет по рекрутерам.
         * @param {string} from Дата от
         * @param {string} to Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportRecruitersAutoFromToGet(from: string, to: string, options?: any): AxiosPromise<ReportAutoRecruitersReport> {
            return localVarFp.apiReportRecruitersAutoFromToGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * отчет рекрутерам.
         * @summary отчет рекрутерам.
         * @param {string} [from] Дата от
         * @param {string} [to] Дата до
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportRecruitersGet(from?: string, to?: string, options?: any): AxiosPromise<ReportRecruitersReport> {
            return localVarFp.apiReportRecruitersGet(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * отчет по источникам наш академия.
     * @summary отчет по источникам наш академия.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportAcademyGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportAcademyGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет по источникам АКЦ.
     * @summary отчет по источникам АКЦ.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportAkcAllGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportAkcAllGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет по источникам наш академия + классик.
     * @summary отчет по источникам наш академия + классик.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportAllSourcesGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportAllSourcesGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет по кандидатам.
     * @summary отчет по кандидатам.
     * @param {string} from Дата от
     * @param {string} to Дата до
     * @param {string} type Тип источника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportCandidatesFromToTypeGet(from: string, to: string, type: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportCandidatesFromToTypeGet(from, to, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет по источникам наш классик.
     * @summary отчет по источникам наш классик.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportClassicGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportClassicGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет по источникам КА.
     * @summary отчет по источникам КА.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportKaAllGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportKaAllGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * список отчетов.
     * @summary список отчетов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportListGet(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет рекрутерам.
     * @summary отчет рекрутерам.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportMentorsGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportMentorsGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * автоматический отчет по рекрутерам.
     * @summary автоматический отчет по рекрутерам.
     * @param {string} from Дата от
     * @param {string} to Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportRecruitersAutoFromToGet(from: string, to: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportRecruitersAutoFromToGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отчет рекрутерам.
     * @summary отчет рекрутерам.
     * @param {string} [from] Дата от
     * @param {string} [to] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiReportRecruitersGet(from?: string, to?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiReportRecruitersGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SourcesApi - axios parameter creator
 * @export
 */
export const SourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSourceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/source/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourcesApi - functional programming interface
 * @export
 */
export const SourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSourceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceSourceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSourceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SourcesApi - factory interface
 * @export
 */
export const SourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SourcesApiFp(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSourceGet(options?: any): AxiosPromise<Array<SourceSourceResponse>> {
            return localVarFp.apiSourceGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourcesApi - object-oriented interface
 * @export
 * @class SourcesApi
 * @extends {BaseAPI}
 */
export class SourcesApi extends BaseAPI {
    /**
     * получить список всех источников.
     * @summary получить список всех источников.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public apiSourceGet(options?: AxiosRequestConfig) {
        return SourcesApiFp(this.configuration).apiSourceGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TypeOfSourcesApi - axios parameter creator
 * @export
 */
export const TypeOfSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypeOfSourceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/typeOfSource/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeOfSourcesApi - functional programming interface
 * @export
 */
export const TypeOfSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeOfSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypeOfSourceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypeOfSourceTypeOfSourceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypeOfSourceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TypeOfSourcesApi - factory interface
 * @export
 */
export const TypeOfSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeOfSourcesApiFp(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех источников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypeOfSourceGet(options?: any): AxiosPromise<Array<TypeOfSourceTypeOfSourceResponse>> {
            return localVarFp.apiTypeOfSourceGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TypeOfSourcesApi - object-oriented interface
 * @export
 * @class TypeOfSourcesApi
 * @extends {BaseAPI}
 */
export class TypeOfSourcesApi extends BaseAPI {
    /**
     * получить список всех источников.
     * @summary получить список всех источников.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeOfSourcesApi
     */
    public apiTypeOfSourceGet(options?: AxiosRequestConfig) {
        return TypeOfSourcesApiFp(this.configuration).apiTypeOfSourceGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * получить список всех источников (не админов).
         * @summary получить список всех пользователей (не админов).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * получить список всех источников (не админов).
     * @summary получить список всех пользователей (не админов).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VacancysApi - axios parameter creator
 * @export
 */
export const VacancysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех вакансий за месяц с группировкой дублей.
         * @summary получить список всех вакансий за месяц с группировкой дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyMonthDoublesGet: async (month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('apiVacancyMonthDoublesGet', 'month', month)
            const localVarPath = `/api/vacancy/{month}/doubles`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех вакансий без группировки дублей.
         * @summary получить список всех вакансий без группировки дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyMonthGet: async (month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('apiVacancyMonthGet', 'month', month)
            const localVarPath = `/api/vacancy/{month}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancysApi - functional programming interface
 * @export
 */
export const VacancysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancysApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех вакансий за месяц с группировкой дублей.
         * @summary получить список всех вакансий за месяц с группировкой дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyMonthDoublesGet(month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VacancyVacancyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyMonthDoublesGet(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех вакансий без группировки дублей.
         * @summary получить список всех вакансий без группировки дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVacancyMonthGet(month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VacancyVacancyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVacancyMonthGet(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VacancysApi - factory interface
 * @export
 */
export const VacancysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancysApiFp(configuration)
    return {
        /**
         * получить список всех вакансий за месяц с группировкой дублей.
         * @summary получить список всех вакансий за месяц с группировкой дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyMonthDoublesGet(month: number, options?: any): AxiosPromise<Array<VacancyVacancyResponse>> {
            return localVarFp.apiVacancyMonthDoublesGet(month, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех вакансий без группировки дублей.
         * @summary получить список всех вакансий без группировки дублей.
         * @param {number} month Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVacancyMonthGet(month: number, options?: any): AxiosPromise<Array<VacancyVacancyResponse>> {
            return localVarFp.apiVacancyMonthGet(month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancysApi - object-oriented interface
 * @export
 * @class VacancysApi
 * @extends {BaseAPI}
 */
export class VacancysApi extends BaseAPI {
    /**
     * получить список всех вакансий за месяц с группировкой дублей.
     * @summary получить список всех вакансий за месяц с группировкой дублей.
     * @param {number} month Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancysApi
     */
    public apiVacancyMonthDoublesGet(month: number, options?: AxiosRequestConfig) {
        return VacancysApiFp(this.configuration).apiVacancyMonthDoublesGet(month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех вакансий без группировки дублей.
     * @summary получить список всех вакансий без группировки дублей.
     * @param {number} month Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancysApi
     */
    public apiVacancyMonthGet(month: number, options?: AxiosRequestConfig) {
        return VacancysApiFp(this.configuration).apiVacancyMonthGet(month, options).then((request) => request(this.axios, this.basePath));
    }
}



import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { X } from "tabler-icons-react"
import { CheckCircle } from "react-feather"

export const ManualHeadHunterResumeAdd = () => {
    let useCases = useContext(UseCasesContext)
    const [amoUsers, setAmoUsers] = useState<{ title: string, value: number }[]>([])
    const [error, setError] = useState<string | null>(null)
    const [showOkModal, setShowOkModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState<{
        url: string,
        responsibleId: number
    }>({
        url: "",
        responsibleId: 0
    })

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if (form.url != "", form.responsibleId != 0) {
            setLoading(true)
            let response = await useCases?.mentorUseCase.ManualResumeProcess(form.url, form.responsibleId)
            setLoading(false)
            if (response instanceof Error) {
                setError(response.message)
            } else {
                setError(null)
                setShowOkModal(true)
                setForm({
                    url: "",
                    responsibleId: 0,
                })
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    useEffect(() => {
        useCases?.mentorUseCase.ReadUsersOfAmo().then(response => {
            if (response instanceof Error) {

            } else {
                let users: { title: string, value: number }[] = [{ title: "Выберите ответсвенного", value: 0 }]
                response.forEach((user) => {
                    users.push({ title: user.name, value: user.id })
                })

                setAmoUsers([...users])
            }
        })
    }, [])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Обработка резюме из HH /</span> Добавление резюме из ручного поиска HH</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление резюме из ручного поиска HH</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: loading,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "url",
                                            title: "Url ссылки на резюме из HH (обязательное условие, чтобы контакты были запрошены через интерфейс HH)",
                                            placeholder: "Вставьте url ссылки на резюме из HH",
                                            value: form.url,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "responsibleId",
                                            title: "Выберите ответственного",
                                            placeholder: "Выберите ответственного",
                                            value: form.responsibleId,
                                            type: FiledType.SelectInt,
                                            options: amoUsers,
                                        },
                                    ]}
                                    btnSmall={true}
                                    btnHide= {loading}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                        {showOkModal?<OkModal close={()=>{setShowOkModal(false)}} />:<></>}
                        {error?<ErrorModal close={()=>{setError(null)}} errMsg={error} />:<></>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const OkModal = (props: {close: () => void}) => {
    return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-header">
            <div className="col-12 row">
                <h5 className="modal-title" style={{textAlign: "center"}}>Лид успешно создан!</h5>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <CheckCircle size={100} color={"#90EE90"} />
                </div>
                
            </div>

        </div>
    </div>
</div>
}

const ErrorModal = (props: {errMsg: string, close: () => void}) => {
    return <div className="modal fade show" id="modalCenter" style={{ display: "block" }} aria-modal="true" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-header">
            <div className="col-12 row">
                <h5 className="modal-title" style={{textAlign: "center"}}>Лид не создан!</h5>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.close() }}></button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <X size={100} color={"#FF7F7F"} />
                </div>
                <div className="row">
                    <span style={{textAlign: "center", color: "red"}}>{props.errMsg}</span>
                </div>
            </div>

        </div>
    </div>
</div>
}
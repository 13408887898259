import { ReportSelectionSourceReport } from "../../repository/api/generated";

export class Report {
    private name: string
    private ident: string

    constructor(name: string, ident: string) {
        this.name = name;
        this.ident = ident;
    }
    
    public Name = (): string => {
        return this.name
    }

    public Ident = (): string => {
        return this.ident
    }
}
export class ReportSources {
    private items: ReportSourcesItem[];
    private sums: Sums;
    constructor(items: ReportSourcesItem[], sums: Sums) {
        this.items = items;
        this.sums = sums;
    }

    public Items = (): ReportSourcesItem[] => {
        return this.items
    }

    public Sums = (): Sums => {
        return this.sums
    }
}

export class ReportSourcesItem {
    private sourceId: number;
    
    private sourceName: string;
    private negotationsCount: number;
    private oneNegotationPrice: number;
    private payments: Payments;
    private interview: SourceType;
    private comedToWelcome : SourceType;
    private welcome: SourceType;
    private education: SourceType;
    private exam: SourceType;
    private stat: SourceType;
    private trial: SourceType;
    private profit?: {profit: number | undefined, romi?: number | undefined};


    constructor(sourceId: number, sourceName: string, negotationsCount: number, oneNegotationPrice: number, payments: Payments, interview: SourceType, welcome: SourceType, comedToWelcome: SourceType, education: SourceType, exam: SourceType, stat: SourceType, trial: SourceType, profit?: {profit: number, romi: number},) {
        this.sourceId = sourceId;
        
        this.sourceName = sourceName;
        this.negotationsCount = negotationsCount;
        this.oneNegotationPrice = oneNegotationPrice;
        this.payments = payments
        this.interview = interview
        this.comedToWelcome = comedToWelcome
        this.welcome = welcome
        this.education = education
        this.exam = exam
        this.stat = stat
        this.trial = trial
        this.profit = profit;
    }

    public SourceId = (): number => {
        return this.sourceId
    }

    public Profit = (): {profit?: number, romi?: number} | undefined => {
        return this.profit
    }

    public SourceName = (): string => {
        return this.sourceName
    }

    public NegotationsCount = (): number => {
        return this.negotationsCount
    }

    public OneNegotationPrice = (): number => {
        return this.oneNegotationPrice
    }

    public Payments = (): Payments => {
        return this.payments
    }

    public Interview = (): SourceType => {
        return this.interview
    }

    public ComedToWelcome = (): SourceType => {
        return this.comedToWelcome
    }

    public Welcome = (): SourceType => {
        return this.welcome
    }

    public Education = (): SourceType => {
        return this.education
    }

    public Exam = (): SourceType => {
        return this.exam
    }

    public Stat = (): SourceType => {
        return this.stat
    }

    public Trial = (): SourceType => {
        return this.trial
    }

}

export class Sums {
    private traffic: SumItem;
    private recruiter: SumItem;
    private mentor: SumItem;
    private hrManagerKA: SumItem;
    private hrDirectorMassHiring: SumItem;
    private headOfStudy: SumItem;
    private fot_rg: SumItem;

    private negotations: SumItem;
    private interview: SumItem;
    private welcome: SumItem;
    private comedToWelcome: SumItem;
    private education: SumItem;
    private exam: SumItem;
    private trial: SumItem;
    private stat: SumItem;
    private profit?: {profit: number | undefined, romi?: number | undefined};

    constructor(traffic: SumItem, recruiter: SumItem, mentor: SumItem, hrManagerKA: SumItem, hrDirectorMassHiring: SumItem, headOfStudy: SumItem, fot_rg: SumItem, negotations: SumItem, interview: SumItem, welcome: SumItem, comedToWelcome: SumItem, education: SumItem, exam: SumItem, trial: SumItem, stat: SumItem, profit?: {profit: number, romi: number},) {
        this.traffic = traffic
        this.recruiter = recruiter
        this.mentor = mentor
        this.hrManagerKA = hrManagerKA;
        this.hrDirectorMassHiring = hrDirectorMassHiring;
        this.headOfStudy = headOfStudy
        this.fot_rg = fot_rg
        this.negotations = negotations
        this.interview = interview
        this.welcome = welcome
        this.comedToWelcome = comedToWelcome
        this.education = education
        this.exam = exam
        this.trial = trial
        this.stat = stat
        this.profit = profit
    }

    public Traffic = (): SumItem => {
        return this.traffic
    }

    public HrManagerKA = (): SumItem => {
        return this.hrManagerKA
    }

    public HrDirectorMassHiring = (): SumItem => {
        return this.hrDirectorMassHiring
    }
    
    public HeadOfStudy = (): SumItem => {
        return this.headOfStudy
    }

    public FOT_RG = (): SumItem => {
        return this.fot_rg
    }

    public Profit = (): {profit?: number, romi?: number} | undefined => {
        return this.profit
    }

    public Recruiter = (): SumItem => {
        return this.recruiter
    }

    public Mentor = (): SumItem => {
        return this.mentor
    }

    public Negotations = (): SumItem => {
        return this.negotations
    }

    public Interview = (): SumItem => {
        return this.interview
    }

    public Welcome = (): SumItem => {
        return this.welcome
    }

    public ComedToWelcome = (): SumItem => {
        return this.comedToWelcome
    }

    public Education = (): SumItem => {
        return this.education
    }

    public Exam = (): SumItem => {
        return this.exam
    }

    public Trial = (): SumItem => {
        return this.trial
    }

    public Stat = (): SumItem => {
        return this.stat
    }
}

export class SumItem {
    private count: number;
    private price: number;
    private percent: number;

    constructor(count: number, price: number, percent: number) {
        this.count = count;
        this.price = price;
        this.percent = percent;
    }

    public Count = (): number => {
        return this.count
    }

    public Price = (): number => {
        return this.price
    }

    public Percent = (): number => {
        return this.percent
    }
}

export class SourceType {
    private count: number;
    private percent: number;
    private price: number;

    constructor(count: number, percent: number, price: number) {
        this.count = count;
        this.percent = percent;
        this.price = price;
    }

    public Count = (): number => {
        return this.count
    }

    public Percent = (): number => {
        return this.percent
    }

    public Price = (): number => {
        return this.price
    }

    public SumWithSourceType = (sourceType: SourceType) : SourceType => {
        let count = this.count + sourceType.Count()
        let sum = (this.count * this.price) + (sourceType.Count() * sourceType.Price())
       
        let newSourceType = new SourceType( count, (this.percent + sourceType.Percent()) / 2, count > 0 ?sum / count:0)
        
        return newSourceType
    }
}

export class Payments {
    private mentor: number;
    private recruiter: number;
    private traffic: number;
    private hrManagerKA: number;
    private hrDirectorMassHiring: number;
    private headOfStudy: number;
    private fot_rg: number;

    constructor(traffic: number, recruiter: number, mentor: number,  hrManagerKA: number, hrDirectorMassHiring: number, headOfStudy: number, fot_rg:number) {
        this.mentor = mentor;
        this.recruiter = recruiter;
        this.traffic = traffic;
        this.hrManagerKA = hrManagerKA;
        this.hrDirectorMassHiring = hrDirectorMassHiring;
        this.headOfStudy = headOfStudy;
        this.fot_rg = fot_rg;
    }

    public Traffic = (): number => {
        return this.traffic
    }

    public Recruiter = (): number => {
        return this.recruiter
    }

    public Mentor = (): number => {
        return this.mentor
    }

    public HrManagerKA = (): number => {
        return this.hrManagerKA
    }

    public HrDirectorMassHiring = (): number => {
        return this.hrDirectorMassHiring
    }

    public HeadOfStudy = (): number => {
        return this.headOfStudy
    }

    public FOT_RG = (): number => {
        return this.fot_rg
    }
}